import Component from "../core/Component";
import SocketAPI from "./SocketAPI";
import { ADDED_TO_COMPONENT, REMOVED_FROM_COMPONENT, ADDING_TO_COMPONENT, REMOVING_FROM_COMPONENT, INITIALIZED } from '../states/ComponentStates';
import { moveTo, pushAt } from '../utils/ArrayUtils';
import { STATE } from "../states";
import Event from "../events/Event";

class AppComponent extends SocketAPI {

    preinitialize(...args) {

        //Holds children elements
        this._children = [];
        this.__handleChildStateChange = this.onChildStateChanged.bind(this);

        //Go ahead and start script
        super.preinitialize(...args);
    }

    async initialize() {

        //Call to create children elements
        this.createChildren();

        //Need to make sure all children are created before we move forward
        await this.__checkCreated();

        //Reset routing here
        this.invalidateChildren();

        //Once everything is setup call to set props
        this.childrenCreated();

        //Finally call initialize
        super.initialize();
    }

    /**
     * PUBLIC API
     */

    addChild( child, omitEvent = false ) {
        return this.addChildAt( child, this.numChildren, omitEvent );
    }

    addChildAt( child, index, omitEvent = false )
    {	
        //let newDisplay = AppComponent.getDisplayElement( child );
        if ( child === undefined ) return;
       
        //If adding past available index throw error
        if( index > this.numChildren ) throw new Error( "#Error# DisplayElementContainer: Cannot add a child element past the container index" );
        
        //Notify before attaching
        let addedEvent;
        if( !omitEvent ) 
        {
            addedEvent = new Event( ADDING_TO_COMPONENT, { index:index, child:child } );
            child.dispatchEvent( addedEvent );
            if( addedEvent.isDefaultPrevented ) return;
        }
        
        //Remove element from old 
        if( child._parent !== undefined ) child._parent.removeChild(child);
        
        //Add component to component data list
        if ( index === this.numChildren )
        {
            this._children.push(child);
        }else{
            this._children = pushAt( this._children, [child], index );
        }
        
        child.setParent(this);
            
        //Dispatch event
        if ( !omitEvent )
        {
            //If not initialized then wait
            if( !child.initialized ) {

                //Add listener to update routes when init
                let fAddHandler = (event) => {

                    child.off(INITIALIZED, fAddHandler);

                    //Make sure to update route list
                    this.invalidateChildren(child, ADDING_TO_COMPONENT);
                }
                child.on(INITIALIZED, fAddHandler);
            }else{
                this.invalidateChildren(child, ADDING_TO_COMPONENT);
            }

            addedEvent = new Event( ADDED_TO_COMPONENT, { index:index, child:child } );
            child.dispatchEvent( addedEvent );
        }
        return child;
    }

    addChildren( childrenItems, omitEvent = false )
    {
        let i, nLen = childrenItems.length;
        for ( i = 0; i < nLen; i++ ) 
        {
            this.addChild( childrenItems[i], omitEvent );
        }
    }

    getChildAt( index )
    {
        if ( index >= this.numChildren ) return;
        return this._children[index];
    }

    getChildrenAt( indeces )
    {
        let i, nLen = indeces.length, newList = [];
        for ( i = 0; i < nLen; i++ ) 
        {
            if( i < this.numChildren ) newList.push(this._children[i]);
        }
        return newList;
    }

    getChildByName( name )
    {
        let child, i, nLen = this._children.length;
        for ( i = 0; i < nLen; i++ ) 
        {
            //console.log( "getChildByName", name, this._children[i].name )
            if ( i < this.numChildren && this._children[i].name === name ) 
            {
                child = this._children[i];
                break;
            }
        }
        return child;
    }

    getChildIndex( child )
    {
        let i, index = -1, nLen = this._children.length;
        for ( i = 0; i < nLen; i++ ) 
        {
            if( this._children[i] === child )
            {
                index = i;
                break;
            }
        }
        return index;
    }

    removeChild( child, omitEvent = false )
    {
        if( child === undefined ) return;
        return this.__removeChildFromList(child, omitEvent);
    }

    removeChildren( childrenItems, omitEvent = false )
    {
        let i, nLen = childrenItems.length;
        for ( i = 0; i < nLen; i++ ) 
        {
            this.removeChild( childrenItems[i], omitEvent );
        }
    }

    removeAllChildren( omitEvent = false )
    {
        let i = this.numChildren;
        while ( --i > -1 )
        {
            this.removeChildAt(i, omitEvent);
        }
    }

    removeChildAt( index, omitEvent = false )
    {
        return this.removeChild(this.getChildAt(index), omitEvent);
    }

    setChildIndex( display, index )
    {		
        if ( display === undefined ) return;
        if( index > this.numChildren || index < 0 ) throw new Error("#Error# DisplayElementContainer: Cannot set a child index past the container index");
        if ( this.getChildIndex(display) === index ) return;
        
        //Get target child and update display list
        //let targetDisplay = this.getChildAt(index);
        let oldIndex = this.getChildIndex(display);

        this._children = moveTo( this._children, oldIndex, index );
    }

    swapChildren( child, child2 )
    {
        //Get old indeces
        let index = this.getChildIndex(child), index2 = this.getChildIndex(child2);
        
        //Swap children
        this.setChildIndex(child, index2);
        this.setChildIndex(child2, index);
    }

    swapChildrenAt( index, index2 )
    {
        //Just call swapChildren :)
        this.swapChildren(this.getChildAt(index), this.getChildAt(index2));
    }

    /**
     * CONNECT API 
     */

    connect( netConnection ) {
        if( !super.connect(netConnection) ) return;
        this.__connectChildren();
    }

    close() {
        this.__connectChildren(false);
        super.close();
    }

    /*
     * PRIVATE API
     */

    //Runs and initiates connection for all children
    __connectChildren( connect = true ) {
        let numChildren = this.numChildren;
        for( let i = 0; i < numChildren; i++ ) {
            let child = this.getChildAt(i);
            if( child instanceof SocketAPI ) {
                if( connect ) {
                    child.connect( this.netConnection );
                }else{
                    child.close();
                }
            }/*else{
                child.service = connect ? this.service : null;
            }*/
        }
    }

    __removeChildFromList( child, omitEvent )
    {
        omitEvent = omitEvent || false;
        let childIndex = this.getChildIndex(child);
        if ( childIndex === -1 ) return;
        
        //Notify before attaching
        let addedEvent;
        if( !omitEvent ) 
        {
            addedEvent = new Event(REMOVING_FROM_COMPONENT, { child:child });
            child.dispatchEvent( addedEvent );
            if( addedEvent.isDefaultPrevented ) return;
        }
        
        //Remove from display list
        this._children[childIndex].parent(null);
        this._children.splice( childIndex, 1 );
        
        if ( !omitEvent ) 
        {
            //Update routing list
            this.invalidateChildren(child, REMOVING_FROM_COMPONENT);

            addedEvent = new Event( REMOVED_FROM_COMPONENT, {child:child} );
            child.dispatchEvent(addedEvent);
        }

        return child;
    }

    async __checkCreated()
    {
        //console.log(`__checkCreated: ${this.numChildren}`);
        if ( this.numChildren === 0 ) return true;
        
        let i, nLen = this.numChildren, simpleChild;
        for ( i = 0; i < nLen; i++ )
        {
            simpleChild = this.getChildAt(i);
            //console.log(`__checkCreated: ${simpleChild} : ${simpleChild} : ${simpleChild.initialized}` );
            if ( simpleChild !== undefined && (simpleChild instanceof Component) && simpleChild.initialized !== true ) {
                await simpleChild.promise(INITIALIZED);
            }
        }
        return true;
    }
    
    /**
	 * PROTECTED API
	 */

    createChildren() {

    }

    childrenCreated() {
        
    }

    destroy() {

        //remove all children on the list
        this.removeAllChildren(true);

        this._children = undefined;
        this._routes = undefined;

        //Call super
        super.destroy();
    }

    onChildAdded(child) {

        //Add listener for state changes
        child.on( STATE, this.__handleChildStateChange );
        //console.log('onChildAdded', child.name, child.state);

        //detect if there is a local connection and if so pass it to child
        if( child instanceof SocketAPI ) {
            child.connect(this._service);
        }//else{
            child.service = this._service;
        //}
        
        //If the comp has a name and state then add
        if( child.name && child.state ) this.setStateProp( String(child.name).toLocaleLowerCase(), { ...child.state } );
    }

    onChildRemoved(child) {

        //Remove listener
        child.on( STATE, this.__handleChildStateChange );

        if( child instanceof SocketAPI ) {
            //Always close connection
            child.close();
        }//else{
            child.service = null;
        //}

        //Also delete the state if available
        if( child.name ) this.deleteStateProp( String(child.name).toLocaleLowerCase() );
    }

    onChildStateChanged( event ) {

        //Get child and check they have a name
        let child = event.currentTarget;
        //console.log( 'onChildStateChanged' );
        if( !child.name ) return;

        //Set prop name
        let stateProp = String(child.name).toLocaleLowerCase();
        //console.log( 'onChildStateChanged2', stateProp, child.name, this._name, child.state, this.state );

        //Modify local state
        if( !child.state || Object.keys(child.state).length === 0 ) {
            this.deleteStateProp( stateProp );
        }else{

            if( event.data.prop ) {
                this.state[stateProp][event.data.prop] = event.data.value;
                this.dispatchEvent( STATE, { 
                    prop: stateProp, 
                    value: this.state[stateProp],
                    prop2: event.data.prop,
                    value2: event.data.value
                });
            }else{
                this.setStateProp( stateProp, { ...child.state } );
            }
        }
    }

    setChildStateProp( ...args ) {
        
        if( !args || Object.keys(args).length === 0 ) return;

        if( typeof(args[0]) == 'string' ) {
            this.__replaceStateVal( ...args );
        }else{
            for( let i in args[0] ) this.__replaceStateVal( i, args[0][i], args[1] === true );
        }
    }

    /**
     * ROUTING 
     */

    //Sets route inside the default component path
    setRoute(newRoute, replace = false) {

        this._localRoute = this._localRoute || {};

        if( replace ) {
            this._localRoute[this.path] = newRoute;
        }else{
            this._localRoute[this.path] = this._localRoute[this.path] || {};
            for( let i in newRoute ) {
                this._localRoute[this.path][i] = newRoute[i];
            }
        }
    }

    //Serves to notify locally when something has changed in the child tree
    invalidateChildren( child, event ) {
        //console.log( 'invalidateChildren', this.name, event );

        //Sets routing
        this.invalidateRouteList( child, event );

        //Listen to process local event handlers
        switch( event ) {
            case ADDING_TO_COMPONENT:
                this.onChildAdded(child);
                break;
            case REMOVING_FROM_COMPONENT:
                this.onChildRemoved(child);
                break;
            default: 
                //nothing here yet
        }
    }

    //Makes changes to available path based on children added
    invalidateRouteList( child, event ) {

        //console.log('invalidateRouteList: ', this.initialized, event, this._localRoute );
        
        //Refresh routes
        let consumingRoute = Object.assign({}, event !== ADDING_TO_COMPONENT ? this._localRoute : 
                                               Object.keys(this._routes).length === 0 ? this._localRoute : this._routes);
        
        if( event === ADDING_TO_COMPONENT ) {
            this.__insertChildRoute(child, consumingRoute);
        }else{
            //We need to go through all children again to create routes
            for( let i = 0; i < this.numChildren; i++ ) {
                this.__insertChildRoute(this.getChildAt(i), consumingRoute);
            }
        }
        //console.log('invalidateRouteList: ', consumingRoute );
        this._routes = consumingRoute;
    }

    __insertChildRoute(targetChild, targetRoute) {
        if( targetChild.routes && Object.keys(targetChild.routes).length > 0 ) {
            if( targetChild instanceof AppComponent ) {
                targetRoute = Object.assign(targetRoute, targetChild.routes);
            }else{
                targetRoute[targetChild.path] = {
                    name: targetChild.name,
                    path: targetChild.path,
                    handlers: targetChild.routes
                }
            }
        }
    }

    /**
     * GETTER / SETTER
     */

    get routes() {
        return this._routes;
    }

    get numChildren()
    {
        return !this._children ? 0 : this._children.length;
    }
}

export default AppComponent;