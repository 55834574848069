import AuthService from '../../clawjs/components/AuthService';

class User extends AuthService {

    /**
     * INIT API
     */

    preinitialize() {
        super.preinitialize("user");
    }

    /**
     * HANDLERS
     */

    async onAuthChanged( loggedIn ) {

        //console.log('onAuthChanged', loggedIn);

        if( loggedIn ) {
            await this.doServiceCall('getMe');
        }else{
            this.deleteStateProp('me');
        }

        //Call this after to update
        super.onAuthChanged(loggedIn);
    }

    /**
     * PROTECTED
     */

    resetState() {
        this.deleteStateProp(["me"]);
    }

    getDefaultState() {
        return {
            me: undefined
        }
    }

    async processResponse( handlerName, response, data ) {
        if( response ) {
            switch( handlerName ) {
                case "getMe" :
                    this.setStateProp('me', response.data);
                    break;
                case "updateMe" :
                    this.setStateProp('me', response.data);
                    break;
                case "deleteMe" :

                    // clear out login creds
                    const auth = this.getService('auth');
                    auth.clearLoginCreds();
                    break;
                default:
                    //nothing here
            }
        }
        return super.processResponse(handlerName, response, data);
    }

    getDefaultOptions() {
        return {
            api: "/users"
        };
    }

    getMap() {
        return {
            getMe: "/me",
            updateMe: { path: "/me", method: "put" },
            deleteMe: { path: "/me", method: "delete" },
            userExists: "/exists/email/{email}"
        };
    }
}

export default User;