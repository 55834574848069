

//load event dependencies
import EventDispatcher from '../events/EventDispatcher';
import Event from '../events/Event';

//Load states
import { LOADING, INITIALIZED, DESTROYED } from '../states/ComponentStates';

/**
 * Application
 */

class Component extends EventDispatcher {

	/**
	 * INIT API
	 */

	constructor(...args) {

		super();
		this._initialized = false;

		//Dispatch loading to listeners
		this.dispatchEvent(new Event(LOADING));

		//Set pre init stage
		this.preinitialize(...args);
	}

	preinitialize() {
		
		//Cant run once its up
		if( this._initialized ) return;

		//Stuff to run before init
		this.initialize();
	}

	initialize() {

		//Cant run once its up
		if( this._initialized ) return;

		//Cant run once its up
		this._initialized = true;

		//Set initialized
		this.onInitialized();
		
		//dispatch init
		this.dispatchEvent(new Event(INITIALIZED));
	}

	/**
	 * PUBLIC API
	 */

	//Used to clean out component and free up resources
	destroy() {

		this._initialized = false;
		this.dispatchEvent(new Event(DESTROYED));
	}

	/**
	 * HANDLERS
	 */

	onInitialized() {
				
	}

	/**
	 * GETTER / SETTERS
	 */

	get initialized() {
		return this._initialized;
	}
}

export default Component;