
//Load from default states
import { INITIALIZED, LOADING, DESTROYED } from './index';

const ADDED_TO_COMPONENT = 'addedToComp';
const REMOVED_FROM_COMPONENT = 'removedFromComp';
const ADDING_TO_COMPONENT = 'addingToComp';
const REMOVING_FROM_COMPONENT = 'removingFromComp';

//export available
export {
    LOADING,
    INITIALIZED,
    DESTROYED,
    ADDING_TO_COMPONENT,
    REMOVING_FROM_COMPONENT,
    ADDED_TO_COMPONENT,
    REMOVED_FROM_COMPONENT
}