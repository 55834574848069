
import './App.css';
import { connectApp } from './api';
import { RouterProvider } from 'react-router-dom';
import { router } from './web/routes';

function App(props) {
  return (
    <div className="App">
      { !props.app.loading && 
        <RouterProvider router={router} />
      }
    </div>
  );
}

export default connectApp(App);
