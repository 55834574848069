
import { createBrowserRouter } from 'react-router-dom';

import Main from '../pages';
import Auth from '../pages/auth';

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: '/auth/signin',
    element: <Auth />
  }
]);