
//ClawJS Classes
import { AT_TARGET } from './EventPhase';

class Event { 
    
    constructor( type, data, bubbles = false, cancelable = false ) {

        this.isDefaultPrevented = false;
        this.isImmediatePropagationStopped = false;
        this.isPropagationStopped = false;
        
        this.eventPhase = AT_TARGET;
        
        this.type = type;
        this.data = data;
        this.bubbles = bubbles || false;
        this.cancelable = cancelable || false;
    }

    /*
    * PUBLIC API
    */

    /**
     * @method
     * @param phase {number} - New phase value to set
     * @description Set event phase value 
     */
    setPhase( phase )
    {
        this.eventPhase = phase;
    }
    
    /**
     * @method
     * @description Prevents the event from carrying out its default functionality
     */
    preventDefault()
    {
        this.isDefaultPrevented = true;
    }

    /**
     * @method
     * @description Prevents the event immediately from continuing in the event tree cycle
     */
    stopImmediatePropagation()
    {
        if( !this.cancelable ) return;
        this.isImmediatePropagationStopped = true;
    }

    /**
     * @method
     * @description Prevents the event from continuing in the event tree cycle
     */
    stopPropagation()
    {
        if( !this.cancelable ) return;
        this.isPropagationStopped = true;
    }

    /**
     * @method
     * @description Returns a clone of the event
     * @returns newEvent {Event} - The event object to clone
     */
    clone()
    {	
        return new Event(this.type, this.data, this.bubbles, this.cancelable);
    }
}
export default Event;