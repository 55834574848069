//ClawJS Classes
import Application from '../clawjs/components/Application';
import Auth from './security/Auth';
import User from './data/User';
import Content from './data/Content';
import Upload from './components/Upload';
import Download from './components/Download';

class APIApp extends Application {

  /**
   * PROTECTED
   */

  getDefaultOptions() {
    console.log(
      process.env.REACT_APP_DEVELOPER_MODE,
      process.env.REACT_APP_DEVELOPER_MODE === 'true' ? 'localhost:8080' : process.env.REACT_APP_API_DOMAIN,
    );
    return {
      url: process.env.REACT_APP_DEVELOPER_MODE === 'true' ? 'localhost:8080' : process.env.REACT_APP_API_DOMAIN,
      secure: process.env.REACT_APP_DEVELOPER_MODE === 'true' ? false : process.env.REACT_APP_API_SECURE === 'true',
    };
  }

  createChildren() {
    super.createChildren();
    this.addChildren([
      new Auth(),
      new Content(),
      new Upload(),
      new Download(),
      new User(),
    ]);
  }
}

export default APIApp;
