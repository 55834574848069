import LogicComponent from './LogicComponent';

/**
 * SAMPLE CALL MAP
 */

/*var callMap = {
    
    //Contacts
    'contacts' : {path: 'contacts', queryparams:{page_size: 20}},
    'contactbyID' : 'contacts/{contactId}',
    'contactbyEmail' : 'contacts/search/email/{email}',
    'contactbyTag' : 'filters/filter/dynamic-filter',
    'addcontact' : 'contacts', //post
    'editcontact' : 'contacts/edit-properties', //put
    'deletecontact' : 'contacts/{contactId}', //delete
    'deleteNotice' : { path: 'constacts/delete', options: { useSocket: true } },
    
    //Deals
    'deals': 'opportunity',//{method: 'opportunity', queryparams:{page_size: 10, cursor: 'E-ABAIICNGoRc35hZ2lsZS1jcm0tY2xvdWRyFAsSB0NvbnRhY3QYgICAgKLThAoMogEIcHJhYmF0aGuIAgAU'}},
    'getdealbycontactID' : '/contacts/{contactId}deals',
    
    //Task
    // <Coming Soon>
    
    //Note
    // <Coming Soon>
    
}*/

class ServiceAPI extends LogicComponent {

    /**
     * INIT API
     */

    preinitialize( name, service, instance ) {
        
        //Set to let parent know if they should connect this comp
        this._autoConnect = true;

        //Sets map and service if passed in
        this._map = this.getMap();
        if( service ) {
            this.service = service;
            //if( this._token ) this._service.auth = { token: this._token };
        }

        super.preinitialize( name, instance );
    }

    /**
     * PUBLIC API
     */

    async call( method, data, options ) {

        //Need to make this by default
        options = options || {};
        options['headers'] = options['headers'] || {};

        //Only set this if its json style format
        if( options.contentType && 
        //Note if you say yer content is JSON you must set this to accept the same ;)
        options.contentType === "application/json" ) options['headers']["Accept"] = "application/json";
        this._defaultOptions = await this.getDefaultOptions();

        //Since we're aiming to share an instance across then we should always set these before a call
        this._service.setOptions(this._defaultOptions);
        this._service.map = this._map;

        //Call and return
        return await this._service.callMap(method, data, options);
    }

    /**
     * PROTECTED API
     */

    //Use this to check data before the call
    async initializeCall( handlerName, data ) {
        
        //Do nothing call is a-ok to go :)
        return data;
    }

    //process call
    async doServiceCall( handlerName, ...args ) {

        let data = !args ? undefined :  
                   args.length === 1 ? args[0] : { _args: args };

        //init call
        data = await this.initializeCall( handlerName, ...args )
        .catch( err => {
            throw err;
        });

        //Carry out call
        let res = await this.call( handlerName, data, typeof(this._map[handlerName]) == 'object' ? this._map[handlerName].options : null )
        .catch( err => {
            throw err;
        });

        //if the server sent back an error then throw error
        if( res && 
            res.data && 
            res.data.statusCode && 
            res.data.statusCode > 399 ) throw res.data;

        //Process data and return
        const result = await this.processResponse( handlerName, res, data )
        .catch( err => {
            throw err;
        });
        
        return result;
    }

    async processResponse( handlerName, response, data ) {

        //We just return the original data that was returned from the call
        return response.data;
    }

    loadDefaultRouting() {

        super.loadDefaultRouting();
        let routes = {};

        //Add routes from what was in the map
        for( let r in this._map ) {
            if( this._routes[r] ) continue;
            routes[r] = { handler: this.doServiceCall.bind(this, r) };
        }

        this.setRoute(routes);
    }

    //Gets default call map
    getMap() {
        return {};
    }

    //Gets default options
    async getDefaultOptions() {
        return {};
    }

    onServiceChanged( value, oldService ) {
        
    }

    /**
     * GETTER / SETTER
     */

    set autoConnect(value) {
        this._autoConnect = value;
    }

    get autoConnect() {
        return this._autoConnect === true;
    }

    set service(value) {
        if( this.service === value ) return;
        let oldService = this._service;
        this._service = value;
        this.onServiceChanged( value, oldService );
    }

    get service() {
        return this._service;
    }

    get api() {
        return !this._defaultOptions ? undefined : this._defaultOptions.api;
    }
}

export default ServiceAPI;