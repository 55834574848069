import ServiceAPI from "./ServiceAPI";
import NetConnection from "../net/NetConnection";

class SocketAPI extends ServiceAPI {

    preinitialize( ...args ) {
        this.__onServerEvent = this.onServerEvent.bind(this);
        super.preinitialize(...args);
    }

    /**
     * PUBLIC API
     */

    connect( netConnection ) {

        if( !netConnection || !(netConnection instanceof NetConnection) ) return false;

        if( this._netConnection ) this.close();
        this._netConnection = netConnection;
        if( this.api ) this._netConnection.on( `${this.api}:event`, this.__onServerEvent );
        return true;
    }

    close() {
        if( this.api ) this._netConnection.off( `${this.api}:event`, this.__onServerEvent );
        this._netConnection = null;
    }

    emit( ...args ) {

        if( this.netConnection ) {
            this.netConnection.setOptions(this.getDefaultOptions());
            this.netConnection.emit( ...args );
        }
    }

    /**
     * HANDLERS
     */

    //Handles events coming from ther server
    onServerEvent(event) {}

    /**
     * GETTER / SETTER
     */

    get netConnection() {
        return this._netConnection;
    }

    get connected() {
        return this._netConnection && this._netConnection.connected;
    }
}

export default SocketAPI;