/* eslint-disable no-useless-escape */
/**
 * @desc Extend Object src with obj2
 * @param src {object}
 * @return obj2 {object}
 * @return {object}
 */
function extend(src, obj2) {
    for (let key in obj2) {
        if (obj2.hasOwnProperty(key)) src[key] = obj2[key];
    }
    return src;
}

/**
 * Reverses the input text.
 *
 * @param {string} input The text to reverse.
 * @return The input text reversed.
 * @customfunction
 */
function reverse(string) {
    if (typeof string != 'string') return null;
    return string.split('').reverse().join('');
}
  
/**
 * @desc Build URL with query parameters
 * @param url {string}
 * @param parameters {object
 * @return {string}
 */
function buildUrl(url, parameters){
    let qs = "";
    for(let key in parameters) {
        let value = parameters[key];
        qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
    }
    if (qs.length > 0){
        qs = qs.substring(0, qs.length-1); //chop off last "&"
        url = url + "?" + qs;
    }
    return url;
}
  
/**
 * @desc Serialize into query paremeters
 * @param parameters {object
 * @return {string}
 */
function serialize(obj, prefix) {
    let str = [], p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
            str.push((v !== null && typeof v === "object") ?
            serialize(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

/**
 * @desc Camelize string
 * @param str {string}
 * @return {string}
 */
function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}
  
/**
 * @desc Converts ISO 8601 date string into JS date object
 * @param string {string}
 * @return {Date}
 */

function getDateFromIso(string) {
    
    try{
        let aDate = new Date();
        
        let regexp = "([0-9]{4})(-([0-9]{2})(-([0-9]{2})" +
                     "(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(\\.([0-9]+))?)?" +
                     "(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?";
        
        let d = string.match(new RegExp(regexp));
        let offset = 0;
        let date = new Date(d[1], 0, 1);

        if (d[3]) { date.setMonth(d[3] - 1); }
        if (d[5]) { date.setDate(d[5]); }
        if (d[7]) { date.setHours(d[7]); }
        if (d[8]) { date.setMinutes(d[8]); }
        if (d[10]) { date.setSeconds(d[10]); }
        if (d[12]) { date.setMilliseconds(Number("0." + d[12]) * 1000); }
        if (d[14]) {
            offset = (Number(d[16]) * 60) + Number(d[17]);
            offset *= ((d[15] === '-') ? 1 : -1);
        }

        offset -= date.getTimezoneOffset();
        let time = (Number(date) + (offset * 60 * 1000));
        return aDate.setTime(Number(time));
    } catch(e){
        return;
    }
}
  
/**
 * @desc Escape special characters for use in a regular expression
 * @param str {string}
 * @return {string}
 */
function escapeRegExp(strToEscape) {
    return strToEscape.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

/**
 * @desc Trim a character from string
 * @param origString {string}    Original string to trim character from
 * @param charToTrim {string}    Character to trim from string
 * @return {string}
 */
function trimChar(origString, charToTrim) {
    charToTrim = escapeRegExp(charToTrim);
    let regEx = new RegExp("^[" + charToTrim + "]+|[" + charToTrim + "]+$", "g");
    return origString.replace(regEx, "");
}

/**
 * @desc Check if passed string is valid or not.
 * @param date {String}    String which gets validated as date (ie: '2017-05-31')
 * @return {Boolean}
 */
function isDate(date) {
    return ((new Date(date) !== "Invalid Date" && !isNaN(new Date(date)) ) ? true : false);
}


/**
 * @desc The fill() method fills all the elements of an array from a start index to an end index with a static value.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/fill
 * @param value {mixed}    Value to fill an array.
 * @param start {integer}    Optional, Start index, defaults to 0.
 * @param end {integer}    End index, defaults to this.length.
 * @return {Array}    The modified array.
 */
/*if (!Array.prototype.fill) {
    Array.prototype.fill = function(value) {

        // Steps 1-2.
        if (this == null) {
        throw new TypeError('this is null or not defined');
        }

        let O = Object(this);

        // Steps 3-5.
        let len = O.length >>> 0;

        // Steps 6-7.
        let start = arguments[1];
        let relativeStart = start >> 0;

        // Step 8.
        let k = relativeStart < 0 ?
        Math.max(len + relativeStart, 0) :
        Math.min(relativeStart, len);

        // Steps 9-10.
        let end = arguments[2];
        let relativeEnd = end === undefined ?
        len : end >> 0;

        // Step 11.
        let final = relativeEnd < 0 ?
        Math.max(len + relativeEnd, 0) :
        Math.min(relativeEnd, len);
        
        // Step 12.
        while (k < final) {
        O[k] = value;
        k++;
        }

        // Step 13.
        return O;
    };
}*/

/**
 * @desc Converts time difference into human readable format.
 *       Returns difference in %d %h %m %s
 *
 *       Sample call: formatTimeDiff(183599000) returns '2d 2h 59m 59s'
 *                or: formatTimeDiff(new Date('2017-08-03T12:59:59'), new Date('2017-08-01T10:00:00')) return '2d 2h 59m 59s'
 *
 * @param {Integer|Date}   Either the time difference in seconds as integer, 
 *                         or two Date() objects.
 * @param {Date}           Optional Date() object to compare with first param Date()
 * @return {String}
 */
/*function formatTimeDiff( ...args ) {
    let delta, response = '';
    if(args.length === 1) {
        // delta passed to convert
        delta = args[0];
    } else if (args.length === 2) {
        // get total seconds between the times
        if ( args[1] > args[0] ) {
            delta = Math.abs(args[1] - args[0]) / 1000;
        } else {
            delta = Math.abs(args[0] - args[1]) / 1000;
        }
    } else {
        throw 'formatTime() accepts 1 or 2 arguments.';
    }
    
    // calculate (and subtract) whole days (workday=8h)
    let workhoursInSeconds = parseFloat(getVar('workhours')) * 3600;
    let days = Math.floor(delta / workhoursInSeconds);
    delta -= days * workhoursInSeconds;

    // calculate (and subtract) whole hours
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    let seconds = Math.floor(delta % 60);

    response += days > 0 ? days + 'd ' : '';
    response += hours > 0 ? hours + 'h ' : '';
    response += minutes > 0 ? minutes + 'm ' : '';
    response += seconds > 0 ? seconds + 's ' : '';

    return response.trim();
};*/

/**
 * @desc Converts time difference or seconds passed into hours.
 *
 *       Sample call: formatTimeDiff(5400) returns '1.5' (hours)
 *                or: formatTimeDiff(new Date('2017-08-01T08:30:00'), new Date('2017-08-01T10:00:00')) return '1.5'
 *
 * @param {Integer|Date}   Either the time difference in seconds as integer, 
 *                         or 2 Date objects (from - to).
 * @param {Date}           Optional Date() object to compare with first param Date()
 * @return {Number}
 */
function formatWorkhours( ...args ) {
    let delta;
    if(args.length === 1) {
        // delta passed to convert
        delta = args[0];
    } else if (args.length === 2) {
        delta = delta = Math.abs(args[1] > args[0] ? args[1] - args[0] : 
                                                     args[0] - args[1]) / 1000;
    } else {
        throw Error('formatWorkhours() accepts 1 or 2 arguments.');
    }
    return Math.round(delta / 3600 * 100) / 100;
}

const __getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const uid = (len) => {
    let buf = []
    , chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    , charlen = chars.length;

  for (let i = 0; i < len; ++i) {
    buf.push(chars[__getRandomInt(0, charlen - 1)]);
  }

  return buf.join('');
}

export {
    uid,
    extend,
    reverse,
    buildUrl,
    serialize,
    camelize,
    getDateFromIso,
    escapeRegExp,
    trimChar,
    isDate,
    formatWorkhours
}