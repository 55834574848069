import Component from '../core/Component';
import { STATE } from '../states/index';

class LogicComponent extends Component {

    /**
     * INIT API
     */

    constructor(...args) {
        super(...args);

        LogicComponent._ID_COUNT_ = isNaN(LogicComponent._ID_COUNT_) ? 0 : LogicComponent._ID_COUNT_ + 1;
        this._id = `logic${LogicComponent._ID_COUNT_}`;
    }

    preinitialize(name, instance) {

        //set instance
        this._name = name || this.constructor.name.toLocaleLowerCase().replace('service', '');

        this._instance = instance;
        this._state = this.getDefaultState();

        //load default routes for this service
        this.loadDefaultRouting();

        //continue initialize
        super.preinitialize();
    }

    initialize() {
        super.initialize();
        this.loading(false);
    }

    /**
     * PUBLIC API
     */

    setParent(parentElement) {
        this._parent = parentElement;
    }

    loading( value = true ) {
        if( (!this.state && !value) || this.state.loading === value ) return;
        this.setStateProp('loading', value);
    }

    getService( name ) {
        if( !this.application || !name ) return;
        return this.application.getChildByName(name);
    }

    /**
     * PROTECTED
     */

    destroy() {

        //Clear out all variables
        this._id = undefined;
        this._routes = undefined;
        this._name = undefined;
        this._instance = undefined;
        this._path = undefined;
        this._parent = undefined;

        //Call super
        super.destroy();
    }

    resetState( state, omitEvent ) {

    }

    getDefaultState() {
        return {
            loading: true
        };
    }

    //Routing makes this service publicly accessible
    loadDefaultRouting() {
        this._routes = this._routes || {};
    }

    setPath(newPath) {
        this._path = newPath;
    }

    //Routes calls to appropriate methods
    setRoute(newRoute, replace = false) {

        if( replace ) {
            this._routes = newRoute;
        }else{
            this._routes = this._routes || {};
            for( let i in newRoute ) {
                if( !this._routes[i] || (this._routes[i].locked && this._routes[i].locked === newRoute[i].locked) ) {
                    this._routes[i] = newRoute[i];
                }
            }
        }
    }

    setStateProp( ...args ) {

        if( !args || Object.keys(args).length === 0 ) return;

        if( typeof(args[0]) == 'string' ) {
            this.__replaceStateVal( ...args );
        }else{
            for( let i in args[0] ) this.__replaceStateVal( i, args[0][i], args[1] === true );
        }
    }

    deleteStateProp( ...args ) {

        if( !args || args.length === 0 ) return;

        args.forEach( prop => {
            let oldValue = this._state[prop];
            delete this._state[prop];
            this.dispatchEvent(STATE, { prop, oldValue });
        });
    }

    setState( value, omitEvent = false ) {
        if( this._state === value ) return;

        //Send old value just in case
        let oldValue = Object.assign({}, this._state);

        //Set new value
        this._state = value;

        //Tempted to dispatch clone of new value (noted)
        if( !omitEvent ) this.dispatchEvent(STATE, { value: value, oldValue: oldValue });
    }

    __replaceStateVal( propName, propVal, omitEvent = false ) {

        //console.log('__replaceStateVal', this._name, propName, propVal, (this._state[propName] === propVal) );
        //No use setting if same value
        if( this._state && this._state[propName] === propVal ) return;
        //console.log('__replaceStateVal2' );
        this._state = this._state || {};
        let oldPropValue = this._state[propName];
        this._state[propName] = propVal;

        if( !omitEvent ) this.dispatchEvent( STATE, { prop: propName, value: propVal, oldValue: oldPropValue } );
    }

    /**
     * GETTER / SETTER
     */

    get parent() {
        return this._parent;
    }

    get name() {
        return this._name;
    }

    get instance() {
        return this._instance;
    }

    get routes() {
        return this._routes;
    }

    get path() {
        return this._path || this.name;
    }

    get state() {
        return this._state;
    }

    get id() {
        return this._id;
    }

    get application() {
        return LogicComponent.__APP__;
    }
}

export default LogicComponent;