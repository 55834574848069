import ServiceAPI from '../../clawjs/components/ServiceAPI';

class ModelAPI extends ServiceAPI {

    /**
     * PROTECTED API
     */

    getDefaultState() {
        return {
            data: []
        }
    }

    getDefaultOptions() {
        return {
            api: `/${this.name}`
        }
    }

    async processResponse( handlerName, response, data ) {

        switch( handlerName ) {
            case "get" :
                if( Array.isArray(response.data) ) this.setStateProp('data', response.data);
                break;
            case "create" :
                //this.setStateProp('data', [ ...this.state.data, response.data ]);
                break;
            default:
                //nothing here
        }
        //We just return the original data that was returned from the call
        return response.data;
    }

    getMap() {
        return {
            get : "/",
            getAt: "/{id}",
            create: { path: "/", method:"post" },
            edit: { path: "/{id}", method: "put" },
            remove: { path: "/{id}", method: "delete" }
        };
    }
}

export default ModelAPI;