import AppComponent from "./AppComponent";
import Service from '../net/Service';
import LogicComponent from './LogicComponent';

class Application extends AppComponent {

    /**
     * INIT API
     */

    preinitialize() {

        //Set a reference to the app here
        LogicComponent.__APP__ = this;

        //Create default service for calls
        this.createService();
        super.preinitialize('app');
    }

    /**
     * PROTECTED API
     */

    destroy() {
        this._service = null;
        super.destroy();
    }

    resetState() {
        let nLen = this.numChildren;
        for( let i = 0; i < nLen; i++ ) {
            let child = this.getChildAt(i);
            child.resetState();
        }
    }

    createService() {
        this._service = new Service(this.getDefaultOptions());
    }

    getDefaultOptions() {
        return {
            url: '127.0.0.1',
            secure: false
        }
    }
}

export default Application;