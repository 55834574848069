import ServiceAPI from '../../clawjs/components/ServiceAPI';
import Storage from '../../clawjs/utils/Storage';
import {uid} from '../../clawjs/utils';
import {INITIALIZED} from '../../clawjs/states';

import get from 'lodash/get';

//Slot to save in the local storage
const TOKEN_KEY = 'reST01';
const SIG_KEY = 'sigRT01';

class Auth extends ServiceAPI {
  /**
   * INIT
   */

  preinitialize() {
    super.preinitialize('auth');
  }

  async initialize() {
    let tokenStatus = String(await Storage.getItem(TOKEN_KEY));
    this.localSignature = String(await Storage.getItem(SIG_KEY));

    this.setStateProp('loggedIn', tokenStatus === 'success');
    super.initialize();

    if (!this.localSignature || this.localSignature === 'null') {
      this.localSignature = uid(24);
      Storage.setItem(SIG_KEY, this.localSignature);
      this.service.registerSignature(this.localSignature);
    }
  }

  async onInitialized() {

    if (!this.application.initialized) await this.application.promise(INITIALIZED);
    this._biometricshandler = this.getService("biometricshandler");
  }

  /**
   * PUBLIC
   */

  async login(data) {
    return await this.doServiceCall('login', data);
  }

  /**
   * PROTECTED
   */

  clearLoginCreds() {
    //Remove saved token from service
    this.service.registerAuth(false);
    //this.service.registerSignature(false);

    //If token unathorized then force sign in again
    Storage.removeItem(TOKEN_KEY);

    this.setStateProp('loggedIn', false);
    this.deleteStateProp('social');
    this.deleteStateProp('provider');

    this.application.resetState();
  }

  async initializeCall(handlerName, data) {
    switch (handlerName) {
      case 'login':
        data.type = data.type ?? 'password';
        data.client_id = process.env.REACT_APP_CLIENT_ID;
        data.client_secret = process.env.REACT_APP_CLIENT_SECRET;

        break;
      case 'logout':
        data = this.service.auth;
        break;
      default:
      //do nothing here
    }

    return data;
  }

  async processResponse(handlerName, response, data) {
    switch (handlerName) {
      case 'login':
        const { access_token } = get(response, "data", {});
        if (access_token) {
          Storage.setItem(TOKEN_KEY, 'success');

          this.service.registerAuth({token: response.data.access_token});
          this.setStateProp('loggedIn', true);
        }
        break;
      case 'logout':
        //Regardless of result we purge token and reset
        this.clearLoginCreds();
        await this._biometricshandler.resetGenericPassword();
        break;
      default:
      //nothing here
    }

    return super.processResponse(handlerName, response, data);
  }

  getDefaultState() {
    //Only return logged in with valid token
    return {
      loggedIn: false,
    };
  }

  getDefaultOptions() {
    return {
      api: '/auth',
    };
  }

  onServiceChanged(service, oldService) {
    if (!this._statusHandler)
      this._statusHandler = this.onStatusEvent.bind(this);
    if (oldService) {
      oldService.off('401', this._statusHandler);
      //this.oldService.off( '403', this._statusHandler );
    }

    if (service) {
      service.on('401', this._statusHandler);
      //service.on( '403', this._statusHandler );
    }
  }

  getMap() {
    return {
      login: {path: '/token', method: 'post'},
      logout: {path: '/user/logout', method: 'post'},
      register: {path: '/user/register', method: 'post'},
      getCode: {path: '/recovery/code/', method: 'post'},
      verifyCode: {path: '/recovery/code/verify', method: 'post'},
      setPassCode: {path: '/recovery/code/pass', method: 'post'},
      setPassword: {path: '/user/newpassword', method: 'post'},
      sendVerificationEmail: '/recovery/verify/{email}',
    };
  }

  /**
   * HANDLERS
   */

  onStatusEvent(event) {
    //console.log('onStatusEvent', this.state.loggedIn );
    switch (event.type) {
      case '401':
        if (this.state.loggedIn) this.clearLoginCreds();
        break;
      default:
      //Nothing to do here yet
    }
  }
}

export default Auth;
