import { observable, action, extendObservable } from 'mobx';
import { STATE, INITIALIZED } from "../states";
import Client from '../core/Client';
import ModelAPI from '../components/ModelAPI';

const observableTypes = {
    props: observable,
    actions: action
};

class MobxClient extends Client {

    /**
     * INIT API
     */

    preinitialize( appClass, mobxObserver ) {

        this.__onModuleInitialized = this.onModuleInitialized.bind(this);

        this.observer = mobxObserver; 
        this.observables = {};
        
        super.preinitialize(appClass);
    } 

    /**
     * PRIVATE
     */

    getObservableProps( logicName ) {

        if( this.observables[logicName] ) return this.observables[logicName];
        let logicModule = logicName === Client.APP ? this.app : this.app.getChildByName(logicName);
        if( !logicModule ) return;
        //console.log('getObservableProps', logicName);//, logicModule.state );
        
        let statefulProps = { 
            props: logicModule.state,
            actions: logicModule.routes
        }
        
        //console.log('getObservableProps2', logicName, logicModule.routes);
        let observableProps = {},
            observableValues = {};

        for( let i in statefulProps ) {
            if( i === 'actions' || 
                i === 'props' || 
                i === 'computed' ) {
                let props = statefulProps[i];
                for( let p in props ) {
                    //console.log('getObservableProps', p, i);
                    if( i === 'actions' ) {
                        //console.log('getObservableProps', p, i);
                        if( 'handler' in props[p] ) {
                            //console.log('getObservableProps', p, i);
                            observableProps[p] = observableTypes[i];
                            observableValues[p] = props[p].handler;
                        }
                    }else{
                        //console.log('getObservableProps', p, i);
                        observableProps[p] = observableTypes[i];
                        observableValues[p] = props[p];
                    }
                }
            }
        }

        //Wrap module state into mobx comp
        this.observables[logicName] = observable( observableValues, observableProps );
        if( !logicModule.initilized ) logicModule.on( INITIALIZED, this.__onModuleInitialized );

        //Update internal module state and add listeners
        //logicModule.setState( observables[logicName].state, true );
        logicModule.on( STATE, this.__handleStateChange );

        //return new observers
        return this.observables[logicName];
    }

    /**
     * HANDLERS
     */

    onModuleInitialized( event ) {

        event.currentTarget.off( INITIALIZED, this.__onModuleInitialized );
        let logicName = event.currentTarget.name;

        //Note that in appcomponents the local route may be in the routes as a child so need to check
        let routes = logicName in event.currentTarget.routes ? event.currentTarget.routes[logicName] : event.currentTarget.routes;
        
        //Add routes to observables
        for( let i in routes ) { 
            if( 'handler' in routes[i] ) {
                this.updateObservableState( logicName, i, routes[i].handler );
            }
        }
    }

    //Catch and respond to module state changes
    onModuleStateChanged( event ) {
        
        let logicName = event.currentTarget.name;
        //console.log('onModuleStateChanged', event.data.prop, logicName );//, observables[logicName] );

        if( event.data.prop ) {
            //Only change single prop if indicated
            this.updateObservableState( logicName, event.data.prop, event.data.value );
            //observables[logicName], event.data.prop, event.data.value);
        }else{
            //I'm going to extend so that the update only happens once
            //extendObservable( observables[logicName], event.data.value );
            for( let i in event.data.value ) this.updateObservableState( logicName, i, event.data.value[i] );
        }
    }

    updateObservableState( name, prop, value ) {

        if( !this.observables[name] ) return;

        try {
            if( prop in this.observables[name] ) {
                //console.log( 'updateObservableState', name, prop, value );
                this.observables[name][prop] = value;
            }else{
                //console.log( 'updateObservableState2', name, prop, value );
                extendObservable( this.observables[name], {[prop] : value} );
            }
        }catch(e) {
            // <- Bug alert o_o, solve this here :P
            //console.log(e);
        }
    }

    processObserver( element, store, logicName ) {
        if( !element.defaultProps ) element.defaultProps = {};
        if( typeof(logicName) == 'object' ) {
            for( let i in store ) {
                element.defaultProps[i.toLocaleLowerCase()] = store[i];
            }
        }else{
            element.defaultProps[(logicName).toLocaleLowerCase()] = store;
        }
        return element;
    }

    processDisplay( element, logicModule, store ) {
        return element;
    }

    /**
     * PUBLIC 
     */

    connect( displayModule, logicModule ) {

        let logicName = this.findDisplayName( displayModule, logicModule );
        let store;

        if( typeof(logicName) == 'object' ) {
            store = {};
            for( let i in logicName ) {
                let logicStoreName = Array.isArray(logicName) ? logicName[i] : i;
                store[logicStoreName] = this.getObservableProps(logicStoreName);
            }
        }else{
            store = this.getObservableProps(logicName);
        }

        return this.processObserver(
            this.observer(this.processDisplay(displayModule, logicName, store)), 
            store, 
            logicName
        );
    }

    connectModel( displayModule, logicModule ) {
        //console.log('connectmodel', logicModule);
        let logicChild = this.app.getChildByName(logicModule);
        if( !logicChild ) this.app.addChild(new ModelAPI(logicModule));
        return this.connect( displayModule, logicModule );
    }
}

export default MobxClient;