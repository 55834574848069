import AuthService from '../../clawjs/components/AuthService';

class Upload extends AuthService {

    /**
     * INIT API
     */

    preinitialize() {
        super.preinitialize("upload");
    }
    
    /**
     * PROTECTED
     */

    getDefaultOptions() {
        return {
            api: "/upload"
        }
    }

    getMap() {
        return {
            getLink: { path: "/link", method: "post" }
        }
    }
}

export default Upload;