
const LOADING       = 'loading';
const READY         = 'ready';
const LOADED        = 'loaded';
const CHANGE        = 'change';
const INITIALIZED   = 'init';
const STATE         = 'state';
const DESTROYED     = 'destroyed';
const REQUEST       = 'request';

//Common states to use
export {
    READY,
    CHANGE,
    INITIALIZED,
    STATE,
    LOADING,
    LOADED,
    DESTROYED,
    REQUEST
}