
//BearCoda Classes
import { CAPTURING_PHASE } from "./EventPhase";
import Event from "./Event";

class EventDispatcher {

    /**
     * INIT API
     */

    constructor(target) {
        
        this._listenerCount = 0;
	    this._eventTarget = !target ? this : target;
    }

    /*
    * PUBLIC API
    */

    /**
     * @method
     * @param type {string} - The event type to listen to
     * @param handler {function} - The handler that should be called when the event is fired
     * @param useCapture {boolean} - Indicates if the event should fire in capture mode only
     * @param priority {number} - Sets the listener priority level. Smaller values places callback higher up the call list
     * @description Register a listener to an event type
     */
    on( eventType, handler, useCapture = false, priority = 0, useWeakReference = false )
    {
        if ( !this._eventListeners ) this._eventListeners = {};
        if ( this._eventListeners[eventType] === undefined ) this._eventListeners[eventType] = [];
        
        let aListeners = this._eventListeners[eventType];
        if ( !isNaN(EventDispatcher.__getHandlerIndex(aListeners, handler))) return;
        
        //Update listeners list
        this.activeListeners = EventDispatcher.__updateActiveListeners( eventType, this.activeListeners, true );
        
        //Add new handler
        aListeners.push(new EventListener(handler, priority, this._listenerCount, useCapture));
        this._listenerCount++;
    }

    /**
     * @method
     * @param type {string} - The event type
     * @param handler {function} - The handler used when the listener registered the event
     * @param useCapture {boolean} - Indicates if the event was using capture mode
     * @description Unregister a listener to an event type
     */
    off( eventType, handler, useCapture = false )
    {
        if ( !this._eventListeners ) return;
        
        //defaults
        useCapture = useCapture || false;
        
        let aListeners = this._eventListeners[eventType];
        let handlerIndex = EventDispatcher.__getHandlerIndex(aListeners, handler);
        if ( isNaN(handlerIndex) ) return;
        
        aListeners.splice(handlerIndex, 1);
        this.activeListeners = EventDispatcher.__updateActiveListeners( eventType, this.activeListeners, false );
    }

    /**
     * @method
     * @param type {string} - The event type
     * @description Register a promise with event. Note you can't unlisten once you registered and thread will pause until event is dispatched
     */
    async promise( eventType ) {

        let eListener = this, fListener;
        let promiseHandler = new Promise((resolve, reject) => {
            //Make sure handler sets off resolve
            fListener = (event) => {

                //First remove itself from listening
                eListener.off(eventType, fListener);

                //Fire off resolve
                resolve(event);
            }
        });

        eListener.on( eventType, fListener );
        return promiseHandler;
    }
    
    /**
     * @method
     * @param type {string} - The event type
     * @description Checks to see if event type has listeners
     * @returns {boolean}
     */
    hasEventListener( eventType )
    {
        return (this.numEventListeners(eventType)>0);
    }

    /**
     * @method
     * @param type {string} - The event type
     * @description Checks to see the amount of listeners registered to the event
     * @returns {number}
     */
    numEventListeners( eventType )
    {
        return !this._eventListeners || !this._eventListeners[eventType] ? 0 : this._eventListeners[eventType].length;
    }

    /**
     * @method
     * @param event {Event} - The event being dispatched
     * @description Dispatch and event from the target
     */
    dispatchEvent( ...args )
    {
        //If not event type then convert
        let event = args[0] instanceof Event ? args[0] : new Event(...args);

        //If no listeners then just ignore
        if ( !this._eventListeners || !this._eventListeners[event.type] ) return false;

        //Find target
        if ( !event.target ) event.target = this._eventTarget;
        event.currentTarget = this;
        
        //Send to queue
        return this.__dispatchQueue(event);
    }

    /*
    * PROTECTED API
    */

    __dispatchQueue( eventObj )
    {
        let i = 0, listener;
        let capture = eventObj.eventPhase === CAPTURING_PHASE;
        let eventList = this._eventListeners[eventObj.type].sort(EventDispatcher.__compareListeners);
        
        while ( i < eventList.length )
        {
            listener = eventList[i];
            if ( listener.useCapture === capture )
            {
                //Call handler
                listener.dispatchEvent(eventObj);
                
                //Stop calls if event propagation is called
                if ( eventObj.isImmediatePropagationStopped ) break;
            }
            
            if( i < eventList.length && listener !== eventList[i] )
            {
                
            }else{
                i++;
            }
        }
        return !eventObj.isDefaultPrevented;
    }
}

/*
 * STATIC API
 */

EventDispatcher.__getHandlerIndex = function( eventList, handler, useCapture )
{
	useCapture = useCapture || false;
	let handlerIndex = NaN;
	if ( eventList ) 
	{
		let i = 0, nLen = eventList.length;
		for ( i = 0; i < nLen; i++ ) 
		{
			if (eventList[i].isListener(handler,useCapture)) 
			{
				handlerIndex = i;
				break;
			}
		}
	}
	return handlerIndex;
}

EventDispatcher.__updateActiveListeners = function( eventType, listeners, add )
{
	if( !listeners ) return (!add ? listeners : [eventType]);
	
	let i = listeners.length;
	while ( --i > -1 )
	{
		if( listeners[i] === eventType )
		{
			if( !add ) listeners.splice( i, 1 );
			return listeners;
		}
	}
	
	if( add ) listeners.push( eventType );
	return listeners;
}

EventDispatcher.__compareListeners = function( l1, l2 )
{
	return l1.priority === l2.priority?(l1.orderIndex<l2.orderIndex?-1:1):(l1.priority>l2.priority? -1:1);
}

/*
 * __PRIVATE__
 */

class EventListener {

    /**
     * INIT API
     */

    constructor( handler, priority = 0, orderIndex, useCapture = false )
    {
        this.handler = handler;
        this.priority = priority || 0;
        this.useCapture = useCapture || false;
        this.orderIndex = orderIndex;
    }

    /**
     * PUBLIC API
     */

    isListener( handler, capture = false )
    {
        capture = capture || false;
        return this.handler === handler && this.useCapture === capture;
    }

    dispatchEvent( event )
    {
        this.handler(event);
    }
}

export default EventDispatcher;