const pushAt = ( itemList, items, index ) =>
{
	return itemList.slice(0, index).concat(items).concat(itemList.slice(index));
}

const moveTo = ( itemList, oldIndex, newIndex, length ) =>
{
	length = length || 1;
	let newList = this.copy(itemList), cutOutItems = newList.splice(oldIndex, length);
	return pushAt(newList, cutOutItems, newIndex);
}

const splice = ( itemList, startIndex, deleteCount, items ) =>
{
	if ( itemList === undefined ) return null;
	deleteCount = deleteCount || 1;
	
	//If zero delete count then ignore
	itemList.splice( startIndex, deleteCount );
	
	if ( items !== undefined ) itemList = pushAt( itemList, items, startIndex );
	return itemList;
}

const copy = ( args ) =>
{
	return [ ...args ];
	/*if( args === undefined ) return;
	let aArgs = [], nLen = args.length;
	for( let i = 0; i < nLen; i++ ) aArgs.push( args[i] );
	return aArgs;*/
}

const indexOf = ( data, searchElement, startPoint ) =>
{
	if ( searchElement === undefined ) return -1;
	
	startPoint = startPoint || 0;
	let index = -1, i, newArray = startPoint > 0 ? data.slice(startPoint) : data;
	for ( i = 0; i < newArray.length; i++ )
	{
		if ( newArray[i] === searchElement )
		{
			index = i;
			break;
		}
	}
	return index;
}

export {
	pushAt,
	moveTo,
	splice,
	copy,
	indexOf
}