
import Component from '../core/Component';
import { serialize } from '../utils';

import base64 from 'base-64';
//import fetch from 'unfetch'
import Storage from '../utils/Storage';

//Slot to save in the local storage
const AUTH_KEY = "reTK01";
const SIG_KEY = "sigRT01";

class Service extends Component { 

    /**
     * INIT API
     */

    preinitialize( options, map, service ) {
        super.preinitialize();
        this.authkey = AUTH_KEY;
        this.sigkey = SIG_KEY;
        
        //Set default
        this.setOptions(options, true);
        this.map = map || {};
        this.service = service || fetch;
        this.signature = null;
    }

    /**
     * PUBLIC
     */

    //Call to clear all props
    destroy() {
        super.destroy();
        this.secure = null;
        this.url = null;
        this.auth = null;
        this.authkey = null;
        this.api = null;
        this.service = null;
    }

    async setOptions( value, clearValues = false ) {
        
        value = value || {};
        this.secure = value['secure'] !== undefined ? value['secure'] : (!clearValues ? this.secure : true);
        this.url = value['url'] || (!clearValues ? this.url : undefined);
        this.authkey = value['authkey'] || (!clearValues ? this.authkey : AUTH_KEY);
        this.api = value['api'] || (!clearValues ? this.api : '');
        await this.loadAuth();
        await this.loadSignature();
    }

    registerAuth( value, key ) {

        //If no key is given you can also get default
        key = key || AUTH_KEY;

        if( !value ) {
            Storage.removeItem( key )
        }else{
            let savedValue = base64.encode(JSON.stringify(value));
            Storage.setItem( key, savedValue );
        }
        //if( this.authKey == key || (key == undefined && this.authKey == undefined ) ) this.auth = value;
        this.auth = value;
    }

    async loadAuth() {
        if( !this.authkey ) {
            this.auth = undefined;
            return;
        }
        try{
            this.auth = JSON.parse(base64.decode(await Storage.getItem(this.authkey)));
        }catch( err ) {
            //couldnt decode error
        }
    }

    registerSignature( value, key ) {

        //If no key is given you can also get default
        key = key || SIG_KEY;

        if( !value ) {
            Storage.removeItem( key )
        }else{
            let savedValue = base64.encode(value);
            Storage.setItem( key, savedValue );
        }
        //if( this.sigkey == key || (key == undefined && this.sigkey == undefined ) ) this.signature = value;
        this.signature = value;
    }

    async loadSignature() {
        
        if( !this.sigkey ) {
            this.signature = undefined;
            return;
        }

        try{
            this.signature = base64.decode(await Storage.getItem(this.sigkey));
        }catch( err ) {
            //couldnt decode error
        }
    }

    async call(path, queryParams, data, fetchArgs) {
        
        fetchArgs = fetchArgs || {};

        let urlParams = queryParams;
        let fetchUrl = 'http'+(this.secure?'s':'')+'://' + this.url + this.api + path;
    
        // data payload vs. url params handling
        let temp, payload = Array.isArray(data) || (typeof(data) != 'object') ? data : {};
        
        //Only run this logic if data is object type
        if( !Array.isArray(data) && (typeof(data) == 'object') ) {
            
            // fill URL placeholders with attributes from data object if passed and available
            for (let attr in data) {
                if (data.hasOwnProperty(attr)) {
                    temp = fetchUrl.replace('{' + attr + '}', data[attr]);
                    if(temp !== fetchUrl) {
                        fetchUrl = temp;
                    } else {
                        // wasnt an attribute for the URL, so we add it to the requests payload
                        payload[attr] = data[attr];
                    }
                }
            }
            
            // fill URL queryparams with attributes from data/payload object if passed and available
            if( urlParams !== undefined ) {
                for (let attr in payload) {
                    if(urlParams.hasOwnProperty(attr)) {
                        urlParams[attr] = payload[attr];
                        delete payload[attr];
                    }
                }
            }
        }
        
        let newFetchArgs = this.getFetchArgs(fetchArgs);
        newFetchArgs.body = typeof(payload) == 'string' || 
                            typeof(payload) == 'number' ? payload : 
                            newFetchArgs.headers["Content-Type"] === "multipart/form-data" ? this.buildForm(payload) :
                            JSON.stringify(payload);
        
        // do not add empty payload
        if(newFetchArgs.body === '{}') 
        { 
            delete fetchArgs['body']; 
            delete newFetchArgs['body'];
        }
        
        // build full fetch URL    
        fetchUrl = fetchUrl + (urlParams !== undefined && Object.keys(urlParams).length > 0 ? "?"+serialize(urlParams) : "");

        let responseData,
            statusCode,
            response;
        //console.log('url', fetchUrl, newFetchArgs );//, this.auth);
        response = await this.fetch(fetchUrl, newFetchArgs)
        .catch( err => {
            console.log( `ServiceError: `, err );
            statusCode = 500;
        });

        //console.log('callresponse', response );
        if (response) {
            statusCode = response.status;
            responseData = await response.text();
            //console.log('callresponse', responseData );

            try {
                // we care about json response content only
                responseData = JSON.parse(responseData);
            }catch(e) {
                statusCode = 500;
                responseData = {
                    statusCode: 500,
                    code: "json_parse_error",
                    description: "Unable to parse json response data"
                };
                console.log(e);
            }
        } else {

            if( statusCode === 500 ) {
                responseData = {
                    statusCode: statusCode,
                    code: "internal_error",
                    description: "An error occurred while making the call."
                };
            }else{
                statusCode = 404;
                responseData = {
                    statusCode: statusCode,
                    code: "no_response",
                    description: "There was no response sent back from the server."
                };
            }
        }
        
        //Dispatch an event for those listening to a specific code
        this.dispatchEvent( String(statusCode), responseData );
        
        return {
            headers: response && response.headers ? response.headers : {},
            statusCode,
            data: responseData,
            response: response || {}
        };
    }

    buildForm(payload) {
        let formData = new FormData();
        for( let i in payload ) formData.append(i, payload[i]);
        return formData;
    }

    //Calls methods mapped to obj
    async callMap(path, data, options) {

        let queryParams = {}, urlPath;
        options = options || {};

        //console.log('callMap', path, this.map[path], this.map);

        if( typeof this.map[path] === 'object' ) {
            urlPath = this.map[path].path;
            queryParams = this.map[path].query;
            
            if( this.map[path].options ) {
                options = Object.assign( this.map[path].options, options );
            }

            if( this.map[path].method ) options.method = this.map[path].method;
        }else{
            urlPath = this.map[path];
        }

        //Call and return
        return await this.call(urlPath, queryParams, data, options);
    }

    /**
     * PROTECTED
     */

   async fetch( url, options ) {
        //console.log( 'fetch', url, options );

        //Need signature for auth calls if set in place
        if( this.signature ) {
            options = options || {headers: {}};
            options["headers"]["signature"] = this.signature;
        }

        let res = await this.service(url, options);
        //console.log( 'fetch2', res );
        return res;
    }

    getFetchArgs(args) {

        let fetchArgs = {
            method: args.method || "GET", 
            mode: "cors",
            cache: "no-cache",
            redirect: "follow",
            headers: {
                "Content-Type" : "application/json"
            }
        };
        
        let encCred, encObj;
        //console.log('getFetchArgs', this.auth);
        if( this.auth ) {
            if( this.auth.token ) {
                encObj = {
                    "Authorization": "Bearer " + this.auth.token
                };
            }else if( this.auth.username && this.auth.password ) {

                //Encrypt info
                encCred = base64.encode(this.username + ":" + this.password);
                
                //Add auth to headers
                encObj = {
                    "Authorization": "Basic " + encCred
                };
            }
        }
        
        //Add auth if required
        if( args && args.headers ) {
            //fetchArgs.headers = args.headers;
            
            //Do not add if it's being overriden
            if( !args.headers["Authorization"] && encObj ) args.headers["Authorization"] = encObj["Authorization"];

            //Merge headers here and delete from main merge
            fetchArgs.headers = Object.assign( {}, fetchArgs.headers, args.headers );
            delete args.headers;
        }else{
            args.headers = encObj;
        }

        return Object.assign( {}, fetchArgs, args );
    };
}

export default Service;