import AuthService from '../../clawjs/components/AuthService';

class Content extends AuthService {

    /**
     * INIT API
     */

    preinitialize() {
        super.preinitialize("content");
    }

    /**
     * PROTECTED
     */

    getDefaultOptions() {
        return {
            api: "/contents"
        }
    }

    getMap() {
        return {
            getList: "/",
            getAt: "/{id}",
            create: { path: "/", method: "post" },
            remove: { path: "/{id}", method: "delete" },
            update: { path: "/{id}", method: "put" }
        }
    }
}

export default Content;