
//ClawJS Classes
import MobxClient from '../clawjs/clients/MobxClient';

//Reever Classes
import APIApp from './APIApp';

//Mobx React
import { observer } from 'mobx-react';

let _client;
function initialize() {
    if( !_client ) _client = new MobxClient( APIApp, observer );
}

function connect( reactModule, logicModule ) {
    initialize();
    return _client.connect( reactModule, logicModule );
}

function connectApp( reactModule ) {
    initialize();
    return _client.connectApp( reactModule );
}

function connectModel( reactModule, logicModule ) {
    initialize();
    return _client.connectModel( reactModule, logicModule );
}

function getObservableProps( logicModule ) {
    initialize();
    return _client.getObservableProps( logicModule );
}

export {
    connect,
    connectApp,
    connectModel,
    getObservableProps
}