import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { connectApp } from "../../api";

const Main = (props) => {

  const navigate = useNavigate();
  useEffect(() => {
    if(!props.app.auth.loggedIn) return navigate('/auth/signin');
  }, [props.app.auth.loggedIn, navigate]);

  return ( 
    <p>Main Home!</p> 
  )
}

export default connectApp(Main);