
//Event phases
const CAPTURING_PHASE = 0;
const AT_TARGET = 1;
const BUBBLING_PHASE = 2;

export { 
    CAPTURING_PHASE, 
    AT_TARGET, 
    BUBBLING_PHASE 
};