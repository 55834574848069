import Component from "../core/Component";
import { INITIALIZED } from "../states";

//Local constant for app
const APP = 'app';

class Client extends Component {

    /**
     * INIT API
     */

    preinitialize( appClass ) {

        this.app = new appClass();

        this.__handleStateChange = this.onModuleStateChanged.bind(this);
        super.preinitialize();
    }

    /**
     * PUBLIC 
     */

    connectApp( displayModule ) {
        return this.connect( displayModule, APP );
    }

    connect( displayModule, logicModule ) {
        return displayModule;
    }

    /**
     * PROTECTED
     */

    findDisplayName( displayModule, logicModule ) {
        return logicModule;
    }

    /**
     * HANDLERS
     */

    //Catch and respond to module state changes
    onModuleStateChanged( event ) {

    }
}

//Make sure this is referenced to the client
Client.APP = APP;
export default Client;