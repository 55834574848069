
const CONNECTING = 'connecting';
const CONNECTED = 'connect';
const DISCONNECTED = 'disconnect';
const CONNECT_ERROR = 'connect_error';
const RECONNECT_ERROR = 'reconnect_error';

export {
    CONNECTING,
    CONNECTED,
    DISCONNECTED,
    CONNECT_ERROR,
    RECONNECT_ERROR
}