import ServiceAPI from './ServiceAPI';
import { STATE, INITIALIZED } from '../states';

class AuthService extends ServiceAPI {

    /**
     * INIT API
     */

    async onInitialized() {

        this.loggedIn = false;

        if( !this.application.initialized ) await this.application.promise( INITIALIZED );

        this.auth = this.getService('auth');
        this.auth.on( STATE, this.onServiceStateChanged.bind(this) );

        //console.log('auth still loading: ', this.auth.state.loading);
        if( !this.auth.state.loading && this.auth.state.loggedIn !== this.loggedIn ) {
            this.onAuthChanged(this.auth.state.loggedIn);
        }

        super.onInitialized();
    }

    /**
     * HANDLERS
     */

    async onServiceStateChanged( event ) {
        switch( event.currentTarget.name ) {
            case "auth" :
                if( this.loggedIn !== this.auth.state.loggedIn ) {
                    this.onAuthChanged(this.auth.state.loggedIn === true);
                }
                break;
            default:
                // no case
        }
    }

    async onAuthChanged( loggedIn ) {
        this.loggedIn = this.auth.state.loggedIn;
        this.setStateProp('loggedIn', this.loggedIn);
    }
}

export default AuthService;