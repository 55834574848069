
//ClawJS Classes
import Service from './Service';
import { CONNECTING, CONNECTED, DISCONNECTED, RECONNECT_ERROR, CONNECT_ERROR } from "../states/ConnectStates";

//Socket IO Classes
import io from 'socket.io-client';

class NetConnection extends Service {

    /**
     * INIT API
     */

    preinitialize( ...args ) {
        super.preinitialize(...args);

        this.__onConnect = this.onConnect.bind(this)
        this.__onDisconnect = this.onDisconnect.bind(this);
        this.__onError = this.onError.bind(this);
    }

    /**
     * PUBLIC API
     */

    connect( uri ) {

        this._uri = uri || this.resolveConnectionURL();
        this.dispatchEvent(CONNECTING);
        
        this._socket = io.connect(this._uri);
        this._socket.on(CONNECTED, this.__onConnect );
        this._socket.on(DISCONNECTED, this.__onDisconnect );
        this._socket.on(CONNECT_ERROR, this.__onError );
        this._socket.on(RECONNECT_ERROR, this.__onError );
    }

    close() {
        if( !this._socket ) return;
        
        this._socket.off(CONNECTED, this.__onConnect );
        this._socket.off(DISCONNECTED, this.__onDisconnect );
        this._socket.off(CONNECT_ERROR, this.__onError );
        this._socket.off(RECONNECT_ERROR, this.__onError );

        this._socket.disconnect();
        this._socket = null;
        this._uri = null;
    }

    on( ...args ) {

        if( ([ CONNECTED, DISCONNECTED,
               CONNECT_ERROR, RECONNECT_ERROR ]).includes(args[0]) ) {
            return super.on(...args);
        }else{
            if(this._socket) return this._socket.on( args[0], args[1] );
        }
    }

    off(...args) {
        if( ([ CONNECTED, DISCONNECTED,
               CONNECT_ERROR, RECONNECT_ERROR ]).includes(args[0]) ) {
            return super.off(...args);
        }else{
            if(this._socket) return this._socket.off( args[0], args[1] );
        }
    }

    emit( ...args ) {
        if( !this._socket ) return;
        this._socket.emit(...args);
    }

    /**
     * PROTECTED API
     */

    async fetch( url, options ) {
        if( options && String(options.method).toLocaleLowerCase() === 'socket' ) {
            let path = url.replace( this.resolveConnectionURL(), '' ) + this.api;
            let emit = this.emit;
            return new Promise((resolve, reject) => {
                let newArgs = !options.body ? [] : '_args' in options.body ? options.body._args : [options.body];
                newArgs.push((data) => {
                    resolve(data);
                });
                emit( path, ...newArgs );
            });
        }else{
            return await super.fetch(url, options);
        }
    }

    resolveConnectionURL() {
        return 'http'+(this.secure?'s':'')+'://' + this.url + this.api;
    }

    onConnect() {
        this.dispatchEvent(CONNECTED);
    }

    onDisconnect() {
        this.dispatchEvent(DISCONNECTED);
    }

    onError() {

    }

    /**
     * GETTER / SETTER
     */

    get connected() {
        return !this._socket ? false : this._socket.connected === true;
    }

    get uri() {
        return this._uri;
    }
}

export default NetConnection;